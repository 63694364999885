<template>
  <router-link
    :to="{ name: 'CompanyProjectDetails', params: { projectId: project.id } }"
    class="linkedProject"
  >
    <div class="tProjectBlock">
      <h4>{{ project.name }}</h4>
      <PriorityFlag :priority="project.priority" />
    </div>

    <div class="mProjectBlock">
      <p>{{ project.description }}</p>
    </div>

    <div class="bProjectBlock">
      <div>
        <h3 class="smallCaps">{{ (tasks && tasks.length) || 0 }} tasks</h3>
      </div>
      <div class="membersCont">
        <AssigneesProfiles :value="project.assignees" />
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRoute } from "vue-router";

import getUser from "@/composables/getUser";
import getCollection from "@/composables/getCollection";

import AssigneesProfiles from "@/components/commons/AssigneesProfiles.vue";
import PriorityFlag from "@/components/commons/PriorityFlag.vue";

export default {
  components: { AssigneesProfiles, PriorityFlag },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { user, userDetails } = getUser();
    const route = useRoute();

    const {
      documents: tasks
    } = getCollection(`companies/${route.params.id}/tasks`, [
      "projectId",
      "==",
      props.project.id
    ]);

    return {
      user,
      userDetails,
      tasks
    };
  }
};
</script>

<style lang="scss" scoped>
.logoAvatar {
  border-radius: 150px;
}
h3 {
  margin: 0;
}
.linkedProject {
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 24px 0;
}
</style>
