<template>
  <div class="container">
    <div class="wrapper">
      <div class="projectsNav">
        <div class="projTitle" style="margin-left: 0">
          <h2>All Projects</h2>
        </div>
      </div>

      <div>
        <BaseButton
          v-if="filterAssignedProjects"
          @click="filterAssignedProjects = false"
        >
          Show all projects
        </BaseButton>
        <BaseButton v-else @click="filterAssignedProjects = true">
          Show only projects assigned to me
        </BaseButton>
      </div>

      <div>
        <Modal :show="isProjectModalShowing" @close="closeProjectModal">
          <div class="newTask">
            <CloseModal @close="closeProjectModal" />
            <div class="newTaskInside">
              <NewProject />
            </div>
          </div>
        </Modal>

        <div class="cardsPage">
          <div class="oneTeamBlock">
            <div class="projectsBlock" v-if="filteredProjects">
              <div
                class="oneProjectBlock clickable"
                v-for="project in filteredProjects"
                :key="project.id"
              >
                <ProjectCard :project="project" />
              </div>

              <div class="newProject clickable" @click="openProjectModal">
                Add a new project
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import { db } from "@/firebase";
import getUser from "@/composables/getUser";

import BaseButton from "@/components/designsystem/BaseButton.vue";
import Modal from "@/components/commons/Modal";
import CloseModal from "@/components/designsystem/CloseModal";

import NewProject from "@/components/commons/NewProject.vue";
import ProjectCard from "@/components/commons/ProjectCard";

export default {
  components: {
    BaseButton,
    Modal,
    CloseModal,
    NewProject,
    ProjectCard
  },
  setup() {
    const { user } = getUser();
    const route = useRoute();

    const filterAssignedProjects = ref(false);

    const projects = ref([]);
    db.collection(`companies/${route.params.companyId}/projects`)
      .orderBy("createdAt", "desc")
      .onSnapshot(snap => {
        projects.value = snap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      });

    const filteredProjects = computed(() => {
      if (!filterAssignedProjects.value) return projects.value;

      return projects.value.filter(project =>
        project.assignees.includes(user.value.uid)
      );
    });

    const isProjectModalShowing = ref(false);
    const openProjectModal = () => (isProjectModalShowing.value = true);
    const closeProjectModal = () => (isProjectModalShowing.value = false);

    return {
      filterAssignedProjects,
      filteredProjects,
      isProjectModalShowing,
      openProjectModal,
      closeProjectModal
    };
  }
};
</script>

<style lang="scss" scoped>
.projectsNav {
  @include flex-row;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.newProject {
  align-items: center;
  background: $base-darkbeige;
  border-radius: 3px;
  display: flex;
  font-family: "GreycliffCF-Bold";
  font-weight: 500;
  height: 232px;
  justify-content: center;
  width: 336px;
}
.newProject:hover {
  background: $base-color;
  color: white;
}
.backdrop {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 31, 96, 0.84);
  display: table;
  transition: opacity 0.3s ease;
}
.modal {
  display: table-cell;
  vertical-align: middle;
}

.newTask {
  background: white;
  border-radius: 9px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 48px 32px;
  position: relative;
  width: 506px;
}
.newTaskInside {
  height: 100%;
  width: 100%;
}
.newTaskDetailsCont {
  display: flex;
  flex-direction: row;
}
.newTaskDetailsItem {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 24px;
}
.roundSelector {
  background: #e4e7f2;
  border: 2px dashed $base-color;
  border-radius: 150px;
  height: 32px;
  margin-right: 8px;
  width: 32px;
}
.newTaskDetailsItem h6 {
  margin: 0;
}
.newElements {
  align-items: center;
}
.newItem {
  padding: 0 12px;
}
.newTaskActions {
  margin-top: 32px;
}
.newTaskActions button {
  margin-right: 8px;
}
.newTaskActions button:last-child {
  margin-right: 0;
}
form {
  margin-bottom: 0;
}

.linkedProject {
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 24px 0;
}
.urgent {
  background: $base-color-red;
  color: $base-color-red;
}
.high {
  background: $base-color-orange;
  color: $base-color-orange;
}
.medium {
  background: $base-color-yellow;
  color: $base-color-yellow;
}
.low {
  background: $base-color-green;
  color: $base-color-green;
}
</style>
