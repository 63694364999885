<template>
  <div class="assignees-profiles">
    <img
      v-if="isUnassigned"
      class="assignees-profiles__picture"
      src="@/assets/icons/icon_assign.svg"
    />
    <template v-else>
      <UserProfileImage
        class="assignees-profiles__picture"
        v-for="user in users"
        :key="user.id"
        :user="user"
      />
    </template>
  </div>
</template>

<script>
import { ref, computed } from "vue";

import { db } from "@/firebase";

import UserProfileImage from "./UserProfileImage.vue";

export default {
  name: "AssigneesProfiles",
  components: {
    UserProfileImage
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const isUnassigned = computed(
      () => props.value.length === 0 || users.value.length === 0
    );

    const allUsers = ref([]);
    db.collection("users").onSnapshot(snap => {
      const docs = snap.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
      allUsers.value = docs;
    });

    const users = computed(() =>
      allUsers.value.filter(user => props.value.includes(user.userId))
    );

    return {
      isUnassigned,
      users
    };
  }
};
</script>

<style lang="scss" scoped>
.assignees-profiles {
  display: flex;
  flex-direction: row-reverse;

  &__picture {
    box-sizing: border-box;
    margin-right: -8px;
    background-color: white;
    border: 2px solid white;

    &:hover {
      z-index: 1;
    }
  }
}
</style>
