<template>
  <svg
    v-if="priority"
    class="priority-flag"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="[`priority-flag--${priority}`]">
      <path
        d="M0 13.3636C0 13.7136 0.286377 14 0.636353 14C0.986328 14 1.27271 13.7136 1.27271 13.3636V9.75757H13.3636C13.6075 9.75757 13.8197 9.61975 13.9364 9.40759C14.0425 9.19543 14.0212 8.9303 13.8727 8.73938L11.6455 5.76965C11.2531 5.25 11.2531 4.50757 11.6455 3.98792L13.8727 1.01819C14.0212 0.827271 14.0425 0.562134 13.9364 0.349976C13.8197 0.137939 13.6075 0 13.3636 0H0.636353C0.286377 0 0 0.286377 0 0.636353V13.3636Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PriorityFlag",
  props: {
    priority: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.priority-flag {
  &--urgent {
    background: $base-color-red;
    color: $base-color-red;
  }

  &--high {
    background: $base-color-orange;
    color: $base-color-orange;
  }

  &--medium {
    background: $base-color-yellow;
    color: $base-color-yellow;
  }

  &--low {
    background: $base-color-green;
    color: $base-color-green;
  }
}
</style>
